.popup {
    color: rgba(255, 255, 255, 1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: right;
    /* align-items: center; */
    background: rgba(32, 32, 32, 0.3);
    z-index: 3;
}

.popup-opened {
    display: flex;
}

.popup__close {
    margin: 0;
    padding: 0;
    border: none;
    background-image: url(../../images/krest.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px 22px;
    position: absolute;
    top: 22px;
    right: 22px;
    max-width: 22px;
    width: 100%;
    min-height: 22px;
    cursor: pointer;
}

.popup__form {
    text-align: center;
    box-sizing: border-box;
    padding: 131px 0 92px;
    position: relative;
    background: #202020;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
    max-width: 520px;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.popup__link {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    margin: 28px auto 0;
    text-decoration: none;
    color: #FFFFFF;
}

.popup__link:hover {
    opacity: 0.6;
}

.popup__link-decoration {
    border-bottom: 2px solid #FFFFFF;
    width: 74px;
    line-height: 28px;
}

/* .popup__navigation {
    margin: 621px auto 0;
} */

.popup__conteiner {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 320px) {
    .popup__form {
        max-width: 320px;
        padding: 144px 0 48px;
    }
}