.cover__link {
    min-height: 740px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    max-width: 1280px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    overflow: hidden;
    position: relative;
    color: #FFFFFF;
    text-decoration: none;
    background-image: url(../../images/red_car.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.cover__link:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #2A2C2F;
    opacity: 0.3;
    z-index: 1;
    transition: opacity 0.5s linear;
}

.cover__subtitle {
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
    z-index: 2;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 122%;
}


.cover__title {
    max-width: 560px;
    margin: 0 auto 32px;
    align-self: center;
    z-index: 2;
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 106%;
    text-align: center;

}

.cover__link:hover:before {
    opacity: 0.8;
}

@media screen and (max-width: 1024px) {

    .cover__link {
        max-width: 1024px;
        min-height: 640px;
    }
}

@media screen and (max-width: 927px) {


    .cover__link {
        max-width: 768px;
        min-height: 480px;
        max-width: 100%;
    }

    .cover__title {
        max-width: 560px;
        margin: 0 auto 32px;
        align-self: center;
        z-index: 2;
        font-style: normal;
        font-weight: 900;
        font-size: 52px;
        line-height: 106%;
        text-align: center;
    }
}


@media screen and (max-width: 578px) {

    .cover__link {
        min-height: 200px;
    }
}

@media screen and (max-width: 798px) {
    /* .cover {
        padding: 0;
    } */

    .cover__title {
        max-width: 488px;
        margin: 170px auto 16px;
        font-size: 32px;
        line-height: 109%;
        white-space: pre-line;
    }
}


@media screen and (max-width: 768px) {
    .cover {
        padding: 0;
    }

    .cover__title {
        max-width: 488px;
        margin: 90px auto 16px;
        font-size: 32px;
        line-height: 109%;
        white-space: pre-line;
    }


    .cover__subtitle {
        margin: 0 auto 90px;
        max-width: 288px;
        font-size: 14px;
        line-height: 114%;
        white-space: pre-line;
    }

    .cover__link {
        max-width: 100%;
        min-height: 200px;
        /* margin-top: 66px; */
    }
}