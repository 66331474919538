.navigator {
    padding: 15px 0 200px;
    /* background-color: black; */
    /* margin: auto; */
    max-width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    z-index: 2;
}

.navigator-small {
    padding: 15px 0 20px;
    max-width: 1280px;
    margin: 0 auto;
}

.navigator-button__ul {
    padding: 0;
    margin: 0 50px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: 18px;
    width: 100%;
}

.navigator-button__ul-link {
    font-size: 24px;
    font-weight: 500;
    color: white;
    line-height: 28px;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.navigator-button__ul-link:hover {
    color: #993300;
}

.navigation__bar {
    background-image: url(../../../images/bar.svg);
    width: 44px;
    height: 44px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
    border: none;
}

@media screen and (max-width: 797px) {
    .navigator {
        padding: 15px 0 130px;
    }

    .navigation__bar {
        padding: 0;
        margin: 0 30px;
    }
}

@media screen and (max-width: 927px) {
    /* .navigator {
        padding: 15px 0 120px;
    } */

    .navigator-button__ul-link {
        font-size: 18px;
        font-weight: normal;
        line-height: 20px;
    }
}

@media screen and (max-width: 768px) {
    .navigator {
        padding: 15px 0 50px;
    }

    .navigator-button__ul-link {
        font-size: 5px;
    }


}

/* @media screen and (max-width: 768px) {
    .navigator {
        padding: 15px 0 120px;
    }
} */