.contact {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    background: white;
    padding: 0 0 125px;
    color: black;
}

.contact__title {
    padding: 70px 0 0;
    margin: 0 70px 50px;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #8B8B8B;
    mix-blend-mode: normal;
    border-bottom: 1px solid rgba(218, 218, 218, 0.5);
}

.contact__text {
    margin: 0 70px 0;
    width: 80%;
    line-height: 40px;
    font-size: 24px;
    max-width: 784px;
}

.contact__text-span {
    /* font-weight: bold; */
    color: #993300;
}

.contact__text-vk {
    cursor: pointer;
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 768px) {
    .contact__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0 50px 0;
    }

    .contact {
        padding: 0 0 50px;
    }
}