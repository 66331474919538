.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", "Intel", "Arial", "Helvetica", sans-serif;
  min-width: 320px;
  background-color: #2A2C2F;
  /* padding: 28px 48px 28px 48px; */
  margin: auto;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
}

/* @media screen and (max-width: 1024px) {
  .page {
    padding: 28px 0 28px 0;
  }
}

@media screen and (max-width: 927px) {
  .page {
    padding: 28px 0 0 0;
  }
}

@media screen and (max-width: 767px) {
  .page {
    padding: 0;
    width: 100%;
  }
} */