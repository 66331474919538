.footer {
    background-color: #1f1f1f;
    color: #fff;
    padding: 50px 0;
    min-height: 18px;
    max-width: 1280px;
    width: 100%;
    margin: auto;
}

.footer__author {
    margin: 0 70px;
    text-align: left;
    width: 100%;
    max-width: fit-content;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .footer__author {
        margin: 0 50px;
        font-size: 12px;
    }

    .footer {
        padding: 30px 0;
    }
}