.header {
    padding: 20px 0;
    background-color: rgb(24, 24, 24, 0.5);
    margin: auto;
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.header_contact {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.header_soc {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.header__logo {
    margin: 0 50px;
    box-sizing: border-box;
    min-height: 52px;
    max-width: 86px;
    cursor: pointer;
}

.header_vk-img {
    width: 18px;
    height: 18px;
    background-image: url(../../images/vk.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    border-radius: 5%;
}

.header-button__ul {
    padding: 0;
    margin: 0 50px 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: 18px;
}

.header-button__ul-link {
    font-size: 24px;
    font-weight: bold;
    color: #993300;
    line-height: 28px;
    font-style: normal;
    text-decoration: none;
}

.header-button__ul-link:hover {
    opacity: 0.8;
}

.header_contacts {
    margin: 0 50px;
    display: flex;
    flex-direction: row;
    gap: 18px;
}


@media screen and (max-width: 1024px) {
    .header {
        padding: 0;
        max-width: 1024px;
    }
}



@media screen and (max-width: 797px) {
    .header {
        position: fixed;
        z-index: 4;
    }

    .header__logo {
        min-height: 38px;
        max-width: 62px;
        margin: 0 30px;
    }

    .header_contact {
        font-size: 13px;
        line-height: 18px;
    }
}