.documents {
    padding: 50px 0 0;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.documents-imgs {
    box-sizing: border-box;
    max-width: 800px;
    width: 100%;
}

@media screen and (max-width: 797px) {
    .documents {
        padding: 0;
    }
}